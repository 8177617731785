import React from 'react';

import { Link } from 'gatsby'

import "./style.css"


function Home() {
    return (
        <div>
            <div className="boda-image" />
            <div className="boda-box">
                <p className="boda-title">Save The Date</p>
                <p className="boda-date">6-Jan-2023</p>
            </div>            
            <div className="intro-text">
                <h1>News</h1>
                <p><b>15-Dic</b> - We added some  <Link to="/events">Events</Link>.</p>                
                <p><b>15-Nov</b> - We added hotels close to Santa Elena <Link to="/travel">Travel</Link>.</p>
                <p><b>27-Sep</b> - We added some cool <Link to="/travel/destinations">Destinations</Link>.</p>
                <p><b>23-Sep</b> - We added some activities in <Link to="/travel/whattosee">What to see</Link>.</p>
                <p><b>22-Sep</b> - We added hotels in <Link to="/travel/hotels">Where to stay</Link>.</p>
                <p><b>13-Jul</b> - Updates in <Link to="/wedding/deutschland">Wedding Day Deutschland</Link>.</p>
                <p><b>4-Jun</b> - We added some <Link to="/memories">Memories</Link>.</p>
            </div>                     
            <div>
                <h1>Save The Date</h1>
                <iframe title="savethedate" src="https://player.vimeo.com/video/695481411?h=1e47dff5b3" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    )
}

export default Home;