import React from 'react'
import { Router } from "@reach/router"
import Layout from '../components/Layout'
import PrivateRoute from "../components/PrivateRoute"
//import ReactDOM from 'react-dom';

//import { graphql } from 'gatsby'
import Home from '../components/Home'
import { Link } from "gatsby"
import { isLoggedIn } from "../services/auth"
//import Profile from '../components/Profile'
import Login from '../components/Login'

// import { Provider }  from 'react-redux';
// import { ApolloProvider } from '@apollo/client';

// import store from '../redux/store';

// import useApolloConfigClient from '../hooks/useApolloConfigClient'


function Root() {
  return (
      <Layout section="home">
        <p>
          { isLoggedIn() ? (
            <>
            </>
          ) : (
            <>
              Please <Link to="/app/login">log in</Link> to see restricted content
            </>
          )}
        </p>    
        <Router>
          <PrivateRoute path="/login" component={Login} />
        </Router>        
        <Home />      
      </Layout>
  )
  }

const Pages = () => <Root />
  
export default Pages